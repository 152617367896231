import {WidgetStoreContent} from './widget.model';

export const WIDGET_LOAD_TRANSLATIONS = '@@widget/LOAD_WIDGET';
export const WIDGET_LOAD_TRANSLATIONS_REQUEST = '@@widget/LOAD_TRANSLATIONS_REQUEST';
export const WIDGET_LOAD_TRANSLATIONS_SUCCESS = '@@widget/LOAD_TRANSLATIONS_SUCCESS';
export const WIDGET_CHANGE_TRANSLATIONS = '@@widget/CHANGE_TRANSLATIONS';
export const WIDGET_TOGGLE_SHOW_CONTACT_MODAL = '@@widget/SHOW_CONTACT_MODAL';

export const widgetLoadTranslations = (locale: string) => {
    return {
        type: WIDGET_LOAD_TRANSLATIONS,
        payload: {
            locale
        }
    };
};

export const widgetLoadTranslationsRequest = (locale: string) => {
    return {
        type: WIDGET_LOAD_TRANSLATIONS_REQUEST,
        payload: {
            locale
        }
    };
};

export const widgetLoadTranslationsSuccess = (widgetStoreContent: WidgetStoreContent) => {
    return {
        type: WIDGET_LOAD_TRANSLATIONS_SUCCESS,
        payload: {
            widgetStoreContent: widgetStoreContent,
        }
    };
};

export const widgetChangeTranslations = (widgetStoreContent: WidgetStoreContent) => {
    return {
        type: WIDGET_CHANGE_TRANSLATIONS,
        payload: {
            features: widgetStoreContent.features,
            advantages: widgetStoreContent.advantages,
            offers: widgetStoreContent.offers,
            sliders: widgetStoreContent.sliders,
            reviews: widgetStoreContent.reviews,
            categories: widgetStoreContent.categories,
            tours: widgetStoreContent.tours,
        }
    };
};

export const widgetToggleShowContactModal = (showContactModal: boolean) => {
    return {
        type: WIDGET_TOGGLE_SHOW_CONTACT_MODAL,
        payload: {
            showContactModal: showContactModal,
        }
    };
}
