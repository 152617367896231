import * as React from 'react';
import {Link, NavLink} from 'react-router-dom';
import './header.scss';
import {FormattedMessage, injectIntl, WrappedComponentProps as InjectedIntlProps} from 'react-intl';
import {AppState} from '../../store/model';
import {getLocale} from '../../store/locale/locale.selectors';
import {Dispatch} from 'redux';
import {localeLoadTranslations} from '../../store/locale/locale.actions';
import {connect} from 'react-redux';
import {changeLocaleInUrl, getMatchingRoute} from '../util/languageUtils';
import {defaultLocale, languages, SelectOption} from '../../locales/messages';
import {successNotificationAdd} from '../../store/notification/notification.actions';
import {widgetLoadTranslations} from '../../store/widget/widget.actions';
import doshaLogo from '../../assets/logo/footerlogo.png';
import {productLoadTranslations} from '../../store/product/product.actions';
import {slide as Menu} from 'react-burger-menu';
import ReactFlagsSelect from "react-flags-select";
import {Category} from "../../store/widget/widget.model";
import {getCategoryContent} from "../../store/widget/widget.selectors";

interface ConnectedState {
    locale: string;
    categories: Category[];
}

interface InternalState {
    showMobileNavbar: boolean;
    isGoodsNavSelected: boolean;
}

interface ConnectedDispatch {
    loadLocaleTranslations: (locale: string) => void;
    showSuccessNotification: (message: string) => void;
    loadWidgetTranslations: (locale: string) => void;
    loadProductTranslations: (locale: string) => void;
}

function mapStateToProps(state: AppState): ConnectedState {
    return {
        locale: getLocale(state),
        categories: getCategoryContent(state),
    };
}

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        loadLocaleTranslations: (locale) => dispatch(localeLoadTranslations(locale)),
        showSuccessNotification: (message) => dispatch(successNotificationAdd(message, {})),
        loadWidgetTranslations: locale => dispatch(widgetLoadTranslations(locale)),
        loadProductTranslations: locale => dispatch(productLoadTranslations(locale)),
    };
};


export class HeaderComponent extends React.PureComponent<ConnectedState & ConnectedDispatch & InjectedIntlProps, InternalState> {

    constructor(props: ConnectedDispatch & ConnectedState & InjectedIntlProps) {
        super(props);
        this.onLanguageChange = this.onLanguageChange.bind(this);
        this.changeShowMobileNavbar = this.changeShowMobileNavbar.bind(this);
        this.onLanguageSelect = this.onLanguageSelect.bind(this);
        this.toggleGoodsNav = this.toggleGoodsNav.bind(this);

        this.state = {
            showMobileNavbar: false,
            isGoodsNavSelected: false,
        }
    }

    onLanguageChange(option: SelectOption) {
        const locale = option.value.toLowerCase() === 'us' ? 'en' : option.value;
        changeLocaleInUrl(locale);
        this.props.loadLocaleTranslations(locale);
        this.props.loadWidgetTranslations(locale);
        this.props.loadProductTranslations(locale);

        setTimeout(() => {
            this.props.showSuccessNotification('notification.locale.change.success');
        }, 3000);
    }

    onLanguageSelect(code: string) {
        const locale = code === 'us' ? 'en' : code;

        changeLocaleInUrl(locale);
        this.props.loadLocaleTranslations(locale);
        this.props.loadWidgetTranslations(locale);
        this.props.loadProductTranslations(locale);

        setTimeout(() => {
            this.props.showSuccessNotification('notification.locale.change.success');
        }, 3000);
    }

    get selectOptions() {
        return languages.map((language) => {
            return (
                <option value={language.value} key={language.value} className={'option'}>{language.label}</option>
                )
        });
    }

    get defaultLocale() {
        let value;
        let locale = this.props.locale;

        if (locale === 'en') {
            locale = 'us';
        }

        languages.forEach(language => {
            if (language.value === locale) {
                value = language;
            }
        });

        if (value === undefined || value === null) {
            value = defaultLocale;
        }

        return value;
    }

    get languageSwitcher() {
        return (
            <select
                value={this.props.locale}
                id={'language-selector'}
            >
                {this.selectOptions}
            </select>
        );
    }

    get categoryPopup() {
        return (<div className={'goods-popup'}>
            {this.props.categories !== undefined && this.props.categories.map((item, key) => (
                <div className={'column'} key={key}>
                    <Link to={`/category-c/${item.id}/`}>
                        {item.shortTitle}
                    </Link>
                    <ul>
                        {item.subcategories.map((subcateogry, key2) => (
                            <li key={key2}>
                                <Link to={`/category-c/${item.id}/${subcateogry.id}`}>
                                    {subcateogry.shortTitle.toLowerCase()}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>);
    }

    changeShowMobileNavbar() {
        this.setState({showMobileNavbar: !this.state.showMobileNavbar});
    }

    toggleGoodsNav() {
        this.setState({isGoodsNavSelected: !this.state.isGoodsNavSelected});
    }

    render() {

        return (
            <div>
                <div className='top-navbar-desktop' id='topNavBar'>
                    <NavLink to={getMatchingRoute('')} className='nav-link logo' exact style={{float: 'left'}}>
                        <img src={doshaLogo} alt={'Logo'}/>
                    </NavLink>

                    <ReactFlagsSelect
                        id={'language-selector'}
                        selected={this.defaultLocale['value'].toUpperCase()}
                        countries={["RU", "US", "UZ"]}
                        customLabels={{ RU: "Pусский", US: "English", UZ: "O'zbekcha" }}
                        onSelect={(code) => this.onLanguageSelect(code.toLowerCase())}
                    />

                    <a className={'phone-button'} href="tel:+998977335075">
                        <svg style={{width: '50px', height: '50px', color: 'rgb(0 125 155)'}}
                             viewBox="0 0 24 24">
                            <path fill="currentColor" d="M11,17V7H4V17H11M11,3A2,2 0 0,1 13,5V19A2,2 0 0,1 11,21H4C2.89,21 2,20.1 2,19V5A2,2 0 0,1 4,3H11M16.5,3H21.5A1.5,1.5 0 0,1 23,4.5V7.5A1.5,1.5 0 0,1 21.5,9H18L15,12V9L15,4.5A1.5,1.5 0 0,1 16.5,3Z" />
                        </svg>
                        <div className={'text'}>
                            ЗАКАЗАТЬ ЗВОНОК
                            <strong>+998 97 733 50 75</strong>
                        </div>
                    </a>
                    <NavLink to={getMatchingRoute('contact')} className='nav-link' activeClassName='active' exact><FormattedMessage id='navbar.name.contact'/></NavLink>
                    <NavLink to={getMatchingRoute('about')}
                             className='nav-link'
                             activeClassName='active'
                             onClick={this.changeShowMobileNavbar.bind(this)}
                             exact>
                        <FormattedMessage id='navbar.name.about'/>
                    </NavLink>
                    <NavLink to={getMatchingRoute('tours')}
                             className='nav-link'
                             activeClassName='active'
                             onClick={this.changeShowMobileNavbar.bind(this)}
                             exact>
                        <FormattedMessage id='navbar.name.tours'/>
                    </NavLink>
                    <NavLink to={getMatchingRoute('category-c')} className='nav-link goods' activeClassName='active' exact>
                        <FormattedMessage id='navbar.name.goods'/>
                        {this.categoryPopup}
                    </NavLink>
                    {/*<NavLink to={getMatchingRoute('category')} className='nav-link' activeClassName='active' exact><FormattedMessage id='navbar.name.products'/></NavLink>*/}
                    <NavLink to={getMatchingRoute('')} className='nav-link' exact><FormattedMessage id='navbar.name.home'/></NavLink>
                </div>

                <div className={'navbar-mobile'}>
                    <div className={'header'}>
                        <svg viewBox='0 0 24 24'>
                            <path fill='currentColor' d='M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z' />
                        </svg>
                        <img src={doshaLogo} alt={'Logo'}/>
                    </div>
                    <Menu isOpen={ this.state.showMobileNavbar }
                          onClose={ this.changeShowMobileNavbar.bind(this) }
                          disableCloseOnEsc
                          menuClassName={ 'sidebar' }>
                        <ul id='menu'>
                            <li>
                                <NavLink to={getMatchingRoute('')}
                                         className='nav-link'
                                         onClick={this.changeShowMobileNavbar.bind(this)}
                                         exact><FormattedMessage id='navbar.name.home'/></NavLink>
                            </li>
                            <li>
                                <NavLink to={getMatchingRoute('about')}
                                         className='nav-link'
                                         activeClassName='active'
                                         onClick={this.changeShowMobileNavbar.bind(this)}
                                         exact><FormattedMessage id='navbar.name.about'/></NavLink>
                            </li>
                            <li>
                                <NavLink to={getMatchingRoute('category-c')}
                                         className='nav-link goods'
                                         activeClassName='active'
                                         onClick={this.changeShowMobileNavbar.bind(this)}
                                         exact>
                                    <FormattedMessage id='navbar.name.goods'/>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={getMatchingRoute('contact')}
                                         className='nav-link'
                                         onClick={this.changeShowMobileNavbar.bind(this)}
                                         activeClassName='active' exact><FormattedMessage id='navbar.name.contact'/></NavLink>
                            </li>
                            <li>
                                <NavLink to={getMatchingRoute('tours')}
                                         className='nav-link'
                                         onClick={this.changeShowMobileNavbar.bind(this)}
                                         activeClassName='active' exact><FormattedMessage id='navbar.name.tours'/></NavLink>
                            </li>
                            {/*<li>*/}
                            {/*    <NavLink to={getMatchingRoute('category')}*/}
                            {/*             className='nav-link'*/}
                            {/*             onClick={this.changeShowMobileNavbar.bind(this)}*/}
                            {/*             activeClassName='active'*/}
                            {/*             exact><FormattedMessage id='navbar.name.products'/></NavLink>*/}
                            {/*</li>*/}
                            <ReactFlagsSelect
                                id={'language-selector'}
                                selected={this.defaultLocale['value'].toUpperCase()}
                                countries={["RU", "US", "UZ"]}
                                customLabels={{ RU: "Pусский", US: "English", UZ: "O'zbekcha" }}
                                onSelect={(code) => this.onLanguageSelect(code.toLowerCase())}
                            />
                        </ul>
                    </Menu>
                </div>
            </div>
        );
    }
}

export const Header = injectIntl(connect(mapStateToProps, mapDispatchToProps)(HeaderComponent));
