import * as React from 'react';
import 'react-animated-slider/build/horizontal.css';
import './orderForm.scss';
import {injectIntl, WrappedComponentProps as InjectedIntlProps} from 'react-intl';
import 'react-multi-carousel/lib/styles.css';
import {Dispatch} from "redux";
import {widgetToggleShowContactModal} from "../../../store/widget/widget.actions";
import {connect} from "react-redux";
import {AppState} from "../../../store/model";
import {isShowContactModal} from "../../../store/widget/widget.selectors";
import acceptIcon from "../../../assets/accept-icon.svg";
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import {FormattedMessage} from 'react-intl';

interface ConnectedState {
    showContactModal: boolean;
}

interface InternalState {
    showContactSentModal: boolean;
    email: string;
    phone: string;
    name: string;
    comment: string;
}

interface ConnectedDispatch {
    toggleShowContactModal: (isShowContactModal: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): ConnectedDispatch => {
    return {
        toggleShowContactModal: (isShowContactModal) => dispatch(widgetToggleShowContactModal(isShowContactModal)),
    };
};

function mapStateToProps(state: AppState): ConnectedState {
    return {
        showContactModal: isShowContactModal(state),
    }
}

class OnlineFormComponentInner extends React.PureComponent<ConnectedState & ConnectedDispatch & InjectedIntlProps, InternalState> {

    constructor(props: ConnectedState & ConnectedDispatch & InjectedIntlProps) {
        super(props);

        this.toggleShowOnlineForm = this.toggleShowOnlineForm.bind(this);
        this.handleEmailSubmission = this.handleEmailSubmission.bind(this);
        this.state = {
            name: '',
            email: '',
            comment: '',
            phone: '',
            showContactSentModal: false
        }
    }

    toggleShowOnlineForm() {
        this.props.toggleShowContactModal(!this.props.showContactModal);
    }

    handleEmailSubmission(event) {
        event.preventDefault();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                comment: this.state.comment
            })
        };
        fetch('/api/contact', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({ showContactSentModal: true }));

        this.setState({
            name: '',
            email: '',
            comment: '',
            phone: ''
        })
    }

    public render() {
        const modalWidth = window.innerWidth > 1000 ? 600 : window.innerWidth - 30;
        const { intl } = this.props;

        return (<div className={'online-form'}>
            <div>
                <p>
                    {intl.formatMessage({id: 'online-form.text'})}
                </p>
            </div>

            <div>
                <form onSubmit={this.handleEmailSubmission}>
                    <label>
                        {intl.formatMessage({id: 'online-form.form.name'})} <span style={{color: 'red'}}>*</span>:
                        <input
                            type="text"
                            name="name"
                            value={this.state.name}
                            onChange={(event) => this.setState({
                                name: event.target.value
                            })}
                            required
                        />
                    </label>
                    <label>
                        {intl.formatMessage({id: 'online-form.form.phone'})} <span style={{color: 'red'}}>*</span>:
                        <input
                            type="text"
                            name="phone"
                            value={this.state.phone}
                            onChange={(event) => this.setState({
                                phone: event.target.value
                            })}
                            required
                        />
                    </label>
                    <label>
                        {intl.formatMessage({id: 'online-form.form.email'})} <span style={{color: 'red'}}>*</span>:
                        <input
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={(event) => this.setState({
                                email: event.target.value
                            })}
                            required
                        />
                    </label>
                    <label>
                        {intl.formatMessage({id: 'online-form.form.message'})}:
                        <textarea
                            name="comment"
                            rows={3}
                            value={this.state.comment}
                            onChange={(event) => this.setState({
                                comment: event.target.value
                            })}
                        />
                    </label>
                    <input type="submit" value={intl.formatMessage({id: 'online-form.form.button'})} />
                </form>
            </div>

            <Rodal
                animation={'rotate'}
                duration={650}
                visible={this.state.showContactSentModal}
                width={modalWidth}
                height={300}
                className={'contact-modal'}
                onClose={() => this.setState({showContactSentModal: false})}>
                <div style={{marginTop: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <div style={{textAlign: 'center', width: '100%'}}>
                        <svg
                            style={{width: '50px'}}
                            xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512"><path fill="#3AAF3C" d="M256 0c141.39 0 256 114.61 256 256S397.39 512 256 512 0 397.39 0 256 114.61 0 256 0z"/><path fill="#0DA10D" fill-rule="nonzero" d="M391.27 143.23h19.23c-81.87 90.92-145.34 165.89-202.18 275.52-29.59-63.26-55.96-106.93-114.96-147.42l22.03-4.98c44.09 36.07 67.31 76.16 92.93 130.95 52.31-100.9 110.24-172.44 182.95-254.07z"/><path fill="#fff" fill-rule="nonzero" d="M158.04 235.26c19.67 11.33 32.46 20.75 47.71 37.55 39.53-63.63 82.44-98.89 138.24-148.93l5.45-2.11h61.06c-81.87 90.93-145.34 165.9-202.18 275.53-29.59-63.26-55.96-106.93-114.96-147.43l64.68-14.61z"/></svg>

                        <h3>{intl.formatMessage({id: 'online-form.form.sent1'})}</h3>
                        <p>{intl.formatMessage({id: 'online-form.form.sent2'})}</p>
                    </div>
                </div>
            </Rodal>

        </div>);
    }
}

export const OnlineFormComponent = injectIntl(connect(mapStateToProps, mapDispatchToProps)(OnlineFormComponentInner));
