import * as React from 'react';
import {FormattedMessage} from "react-intl";
import './contact.scss';
import telegramLogo from "../../assets/Telegram_logo.svg";

export class Contact extends React.PureComponent {

    render() {
        return (
            <div className="contact-container">
                <div className={'details'}>
                    <div className={'header'}>
                        <h1><FormattedMessage id={'page.contact.detail.title'} /></h1>
                        <h4><FormattedMessage id={'page.contact.detail.subtitle'} /></h4>
                    </div>
                    <div className={'body'}>
                        <div className={'item'}>
                            <p><FormattedMessage id={'page.contact.detail.body.item1.title'} /></p>
                            <hr/>
                            <p className={'value'}>OOO "BARSO METAL" ТМ "BARSO"</p>
                        </div>
                        <div className={'item'}>
                            <p><FormattedMessage id={'page.contact.detail.body.item2.title'} /></p>
                            <hr/>
                            <p className={'value'}><FormattedMessage id={'page.contact.detail.body.item2.value'} /></p>
                        </div>
                        <div className={'item'}>
                            <p><FormattedMessage id={'page.contact.detail.body.item3.title'} /></p>
                            <hr/>
                            <p className={'value'}><FormattedMessage id={'page.contact.detail.body.item3.value'} /></p>
                        </div>
                        <div className={'item'}>
                            <p><FormattedMessage id={'page.contact.detail.body.item4.title'} /></p>
                            <hr/>
                            <p><a href="tel:+998901684477">+998 90 168 44 77</a></p>
                            <p><a href="tel:+998994684477">+998 99 468 44 77</a></p>
                            <br />
                            <hr/>
                            <p>Тур оператор: Юлдошев Жонибек</p>
                            <p><a href="https://t.me/barso_metal" style={{display: 'flex', alignItems: 'center', height: '30px'}}>+998 97 733 50 75
                                <img src={telegramLogo} style={{'width': '25px', marginLeft: '20px'}} alt="Telegram icon"/>
                            </a></p>
                        </div>
                        <div className={'item'}>
                            <p><FormattedMessage id={'page.contact.detail.body.item5.title'} /></p>
                            <hr/>
                            <p><a href="mailto:info@barso.uz">info@barso.uz</a></p>
                        </div>
                        <div className={'item'}>
                            <p><FormattedMessage id={'page.contact.detail.body.item6.title'} /></p>
                            <hr/>
                            <p><a href="www.barso.uz">www.barso.uz</a></p>
                        </div>
                    </div>
                </div>
                <div className="map-container">
                    <a
                        href="https://yandex.ru/maps/org/barso/147054071060/?utm_medium=mapframe&utm_source=maps"
                        style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '0'}}
                    >Barso</a><a
                    href="https://yandex.ru/maps/10335/tashkent/category/metal_structures/184106658/?utm_medium=mapframe&utm_source=maps"
                    style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '14px'}}
                >Металлоконструкции в Ташкенте</a>
                    <iframe src="https://yandex.ru/map-widget/v1/-/CCUfQZTrCB" width="100%" height="450" frameBorder="1" allowFullScreen={true}
                            style={{position: 'relative'}}></iframe>
                </div>
            </div>
        );
    }
}
