import { AppState } from '../model';
import { createSelector } from 'reselect';
import { WidgetStore } from "./widget.model";


export const getWidgetStore = (state: AppState): WidgetStore  => state.widget;

export const getFeaturesContent = createSelector(
    [getWidgetStore],
    (widgetStore) => widgetStore.features,
);

export const getToursContent = createSelector(
    [getWidgetStore],
    (widgetStore) => widgetStore.tours,
);

export const getAdvantagesContent = createSelector(
    [getWidgetStore],
    (widgetStore) => widgetStore.advantages,
);

export const getOffersContent = createSelector(
    [getWidgetStore],
    (widgetStore) => widgetStore.offers,
);

export const getSlidersContent = createSelector(
    [getWidgetStore],
    (widgetStore) => widgetStore.sliders,
);

export const getReviewsContent = createSelector(
    [getWidgetStore],
    (widgetStore) => widgetStore.reviews,
);

export const getCategoryContent = createSelector(
    [getWidgetStore],
    (widgetStore) => widgetStore.categories,
)

export const isShowContactModal = createSelector(
    [getWidgetStore],
    (widgetStore) => widgetStore.showContactModal,
)
